import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container mt-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "table table-striped" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Назва книги"),
              _createElementVNode("th", null, "Дії")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.arrayBooks, (book) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: book.id
              }, [
                _createElementVNode("td", null, _toDisplayString(book.title), 1),
                _createElementVNode("td", null, [
                  _createElementVNode("a", {
                    href: book.link
                  }, [
                    _createElementVNode("button", {
                      href: book.link,
                      style: {"margin-top":"5px","margin-right":"10px"}
                    }, _cache[0] || (_cache[0] = [
                      _createTextVNode(" ЧИТАТИ "),
                      _createElementVNode("i", {
                        style: {"color":"#2655ba"},
                        class: "fas fa-book fa-2x"
                      }, null, -1)
                    ]), 8, _hoisted_6)
                  ], 8, _hoisted_5)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}